import React from "react"
import "./style.scss"

function DownArrow({ color }) {
  return (
    <div className="downarrow" style={{ color: color }}>
      <div className="downarrow__text">scroll down</div>
      <div className="downarrow__arrows"></div>
    </div>
  )
}

export default DownArrow
