import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import GoogleMap from "../GoogleMap"
import "./style.scss"
import {
  faEnvelope,
  faLocationPin,
  faPhone,
  faPersonRunning,
  faDog,
} from "@fortawesome/free-solid-svg-icons"

export default function About({ refProp }) {
  return (
    <section ref={refProp} id="about">
      <div className="container fixed">
        <div className="about__content">
          <h2
            className="about__head"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-duration="800"
            data-sal-easing="easeOutCubic"
          >
            About
          </h2>
          <div className="about__body">
            <div className="about__detail">
              <p className="about__intro">
                I am a passionate <em>frontend developer</em> located in
                Calgary. Over the past two years, I have pursued self-taught
                programming and worked on backend projects to gain a broader
                understanding of web development. However, my current focus is
                on expanding my knowledge of frontend development within the
                spectrum of web development skills. I believe this will bring me
                a great sense of accomplishment on my journey in web
                development.
                <br /> <br />
                Feel free to contact me if you're looking for a partner to work
                on <em>an interesting project</em> or even if you just want
                someone to go jogging with! ( YES{" "}
                <em>
                  I like jogging <FontAwesomeIcon icon={faPersonRunning} />
                </em>{" "}
                ) Or you want to talk about your pet as well ( I am a{" "}
                <em>
                  dog person <FontAwesomeIcon icon={faDog} />
                </em>{" "}
                and currently own a Bichon Frise as a pet and in the past, I
                volunteered at Almost Home animal shelter.)
              </p>
              <ul className="about__info">
                <li>
                  <a href="mailto: beckyenwen@gmail.com">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span>beckyenwen@gmail.com</span>
                  </a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faPhone} />
                  <span>
                    <tel>(403) 383-5767</tel>
                  </span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faLocationPin} />
                  <span>
                    <address>7000 Somervale Court SW Calgary, Canada</address>
                  </span>
                </li>
              </ul>
            </div>
            <div className="about__map">
              <span
                style={{
                  position: "absolute",
                  top: "3px",
                  right: "-120px",
                  color: "#999",
                  fontFamily: "Calligraffitti",
                  fontWeight: "600",
                  fontSize: "12px",
                  letterSpacing: "3px",
                }}
              >
                /* my location */
              </span>
              <GoogleMap />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
