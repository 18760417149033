import { useState, useEffect } from "react"

export default function useTimeline() {
  const [items, setItems] = useState([])
  const isInViewport = el => {
    const rect = el.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  const isOutViewport = el => {
    const rect = el.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.top >=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  const run = () => {
    items.forEach(item => {
      if (isInViewport(item)) {
        item.classList.add("show")
      }
      if (isOutViewport(item)) {
        item.classList.remove("show")
      }
    })
  }

  const init = () => {
    const items = document.querySelectorAll("#timeline li")
    if (items.length > 0) {
      setItems(document.querySelectorAll("#timeline li"))
    }
  }

  useEffect(() => {
    if (items.length > 0) {
      window.addEventListener("load", run)
      window.addEventListener("resize", run)
      window.addEventListener("scroll", run)
    }
    return () => {
      window.removeEventListener("load", run)
      window.removeEventListener("resize", run)
      window.removeEventListener("scroll", run)
    }
  }, [items])

  return {
    init,
  }
}
