import React, { useContext, useEffect } from "react"

import { faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons"
import { ModalContext } from "../../ModalContext"

import DownArrow from "../DownArrow"
import useTimeline from "./useTimeline"
import "./style.scss"

export default function Timeline({ refProp }) {
  const { init } = useTimeline()
  let { handleShowModal, FontAwesomeIcon, faDesktop } = useContext(ModalContext)

  useEffect(() => {
    init()
  }, [])

  return (
    <section ref={refProp} id="timeline" className="py-3">
      <DownArrow color="#626365" />
      <h2
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-duration="800"
        data-sal-easing="easeOutCubic"
        className="timeline__title"
      >
        Work Timeline
      </h2>
      <ul className="timeline__wrapper">
        <li>
          <div>
            <span className="duration">Nov 2021 - Oct 2022</span>
            <h3>
              Push Operations
              <FontAwesomeIcon
                tabIndex="0"
                className="timeline__link"
                icon={faDesktop}
                color="#1a2930"
                onClick={() => handleShowModal(true, "pushoperation1", refProp)}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    handleShowModal(true, "pushoperation1", refProp)
                  }
                }}
              />
              <FontAwesomeIcon
                tabIndex="0"
                className="timeline__link"
                icon={faDesktop}
                color="#1a2930"
                onClick={() => handleShowModal(true, "pushoperation2", refProp)}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    handleShowModal(true, "pushoperation2", refProp)
                  }
                }}
              />
            </h3>
            <ul>
              <li>
                I developed two products specifically for the catering industry.
                On the frontend, I built a mobile app that includes a
                comprehensive list of all employees and their respective details
                within a month. In addition, I also constructed a complex web
                application for tracking applicants. On the backend, made
                improvements on the API in Laravel.
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div>
            <span className="duration">Nov 2019 - Jun 2021</span>
            <h3>
              Innova Solutions USA{" "}
              <FontAwesomeIcon
                tabIndex="0"
                className="timeline__link"
                icon={faDesktop}
                color="#1a2930"
                onClick={() =>
                  handleShowModal(true, "innovasolutions", refProp)
                }
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    handleShowModal(true, "innovasolutions", refProp)
                  }
                }}
              />
            </h3>
            <ul>
              <li>
                Built platform for healthcare technology company
                ChangeHealthCare in the US.
              </li>
              <li>
                Frontend: developed web app of management of medical
                professionsal's patients, and gain credits from team members by
                ensuring the quality of UI. Backend: participated in a backend
                project for doctors as a platform to transfer their patients to
                other hospitals. I also levaraged AWS services like pinpoint and
                cloudformation, and got AWS cloud practitioner certificate.
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div>
            <span className="duration">Nov 2017 - Jul 2019</span>
            <h3>
              Delta Electronics
              <FontAwesomeIcon
                tabIndex="0"
                className="timeline__link"
                icon={faDesktop}
                color="#1a2930"
                onClick={() => handleShowModal(true, "delta1", refProp)}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    handleShowModal(true, "delta1", refProp)
                  }
                }}
              />
              <FontAwesomeIcon
                tabIndex="0"
                className="timeline__link"
                icon={faDesktop}
                color="#1a2930"
                onClick={() => handleShowModal(true, "delta2", refProp)}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    handleShowModal(true, "delta2", refProp)
                  }
                }}
              />
            </h3>
            <ul>
              <li>
                This is the first time I have built a single page application on
                my own for internal staff use. I created task and document
                management features, as well as file uploading capabilities.
                Additionally, I code split my JavaScript code on a route level,
                which helped to ensure that the application loads quickly when
                initially opened.
              </li>
              <li>
                In an existing project, I utilized GraphQL to minimize the need
                for frequent requests to servers.
              </li>
              <li>
                I assumed responsibility for a form project that utilizes
                ant-design, with the aim of enabling users to create multiple
                forms simultaneously.
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div>
            <span className="duration">Feb 2017 - Nov 2017</span>
            <h3>
              Eastern Home Shopping & Leisure
              <a
                href="https://m.etmall.com.tw/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Etmall"
                className="timeline__link"
              >
                <FontAwesomeIcon icon={faSquareArrowUpRight} color="#1a2930" />
              </a>
            </h3>
            <ul>
              <li>
                This was my first time to building mobile e-commerce web app
                using react.
              </li>
              <li>
                I improved the page loading speed of a website by implementing
                various techniques such as lazy loading and image optimization,
                resulting in an improved review score of 90 on Google PageSpeed.
              </li>
              <li>
                On a biweekly basis, I initiated a study group with my team
                members to provide tutoring for junior front-end developers.
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div>
            <span className="duration">Feb 2014 - Dec 2016</span>
            <h3>
              EzTravel
              <a
                href="https://flight.eztravel.com.tw/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Eztravel"
                className="timeline__link"
              >
                <FontAwesomeIcon icon={faSquareArrowUpRight} color="#1a2930" />
              </a>
            </h3>
            <ul>
              <li>
                developed websites for booking flight tickets and domestic
                hotels using jQuery.
              </li>
              <li>
                discussed with web designers regarding the layout of the
                company's official website, which included the flight booking
                and domestic hotel booking systems.
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </section>
  )
}
