import React, { Children, useContext } from "react"
import ReactDom from "react-dom"
import pushOperation1 from "../images/timeline/closeJobModal.jpg"
import pushOperation2 from "../images/timeline/offerlist.png"
import innovasolutions from "../images/timeline/innova.jpg"

import delta1 from "../images/timeline/delta1.png"
import delta2 from "../images/timeline/delta2.png"
import eztravel1 from "../images/timeline/eztravel1.png"
import eztravel2 from "../images/timeline/eztravel2.png"
import twattractions from "../images/timeline/twattractions.png"
import { ModalContext } from "../ModalContext"
import "../hooks/style.scss"

const projects = {
  twattractions: {
    src: twattractions,
    alt: "tw attractions",
  },
  pushoperation1: {
    src: pushOperation1,
    alt: "pushoperation1",
  },
  pushoperation2: {
    src: pushOperation2,
    alt: "pushoperation2",
  },
  delta1: {
    src: delta1,
    alt: "delta1",
  },
  delta2: {
    src: delta2,
    alt: "delta2",
  },
  eztravel1: {
    src: eztravel1,
    alt: "eztravel1",
  },
  eztravel2: {
    src: eztravel2,
    alt: "eztravel2",
  },
  innovasolutions: {
    src: innovasolutions,
    alt: "innova solutions",
  },
}

const ImageModal = ({ refProp }) => {
  let { modalContent, showModal } = useContext(ModalContext)
  // TODO: fix the document does not define error in ssg gatsby
  const projectImage = projects[modalContent]
  if (typeof document !== "undefined") {
    return ReactDom.createPortal(
      <div
        ref={refProp}
        role="dialog"
        className={`fixed project-image__screenshots ${
          showModal ? "show" : ""
        }`}
      >
        <img
          loading="lazy"
          key={`${modalContent}`}
          src={projectImage?.src}
          alt={projectImage?.alt}
        ></img>
      </div>,
      document.querySelector("body")
    )
  } else {
    return null
  }
}

export default ImageModal
