import React, { useContext } from "react"
import "./style.scss"

import DownArrow from "../DownArrow"
import coffee from "../../images/original/roland-denes-unsplash.jpg"
import { ModalContext } from "../../ModalContext"

const FRONTEND = ["react", "graphql", "redux", "typescript"]
const BACKEND = ["C#", "ASP.net", "nodejs", "sql"]

export default function Skill({ refProp }) {
  let { handleShowModal, FontAwesomeIcon, faDesktop } = useContext(ModalContext)

  return (
    <section ref={refProp} id="skill">
      <div className="container fixed">
        <div className="skill__content">
          <h2
            className="skill__head"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="800"
            data-sal-easing="easeOutCubic"
          >
            Skills
          </h2>
          <div className="skill__body">
            <div className="skill__item">
              <img className="skill__img" src={coffee} alt="Hi! I'm Beck" />
            </div>
            <div className="skill__item">
              <div className="skill__project">
                <h3>
                  See my current nextjs side project{" "}
                  <FontAwesomeIcon
                    tabIndex="0"
                    className="project-image__link"
                    icon={faDesktop}
                    color="white"
                    onClick={() =>
                      handleShowModal(true, "twattractions", refProp)
                    }
                    onKeyPress={e => {
                      if (e.key === "Enter") {
                        handleShowModal(true, "twattractions", refProp)
                      }
                    }}
                  />
                </h3>
                <div className="skill__skills">
                  <div className="buttons_group">
                    <a
                      href="https://github.com/Becklin/TWattractions"
                      target="_blank"
                    >
                      Github
                    </a>
                    <a
                      className="waviy-text"
                      href="https://twattractions.vercel.app/"
                      target="_blank"
                    >
                      <span style={{ "--i": 1 }}>L</span>
                      <span style={{ "--i": 2 }}>I</span>
                      <span style={{ "--i": 3 }}>N</span>
                      <span style={{ "--i": 4 }}>K</span>
                    </a>
                  </div>
                </div>
              </div>
              <h3>These are some technology I've been using with recently</h3>
              <div className="skill__skills">
                {FRONTEND.map(skill => (
                  <span className="tag">{skill.toUpperCase()}</span>
                ))}
              </div>
            </div>
            <div className="skill__item">
              <h3>
                These are backrend framework I've been working with recently
              </h3>
              <div className="skill__skills">
                {BACKEND.map(skill => (
                  <span className="tag">{skill.toUpperCase()}</span>
                ))}
              </div>
            </div>
            <div className="skill__item">
              <q>
                Beck is passionate about front-end programming, enthusiastically
                handles every task assigned not only in time but also
                outstandingly. Despite of being detail-oriented to web layout as
                well as being vocal about his ideas, he still respects his
                superiors, and manages to solve projects’ problems through
                effective communication. He is friendly, easy to get along with,
                and respected by his co-workers.{" "}
                <span className="skill__recommend">
                  - Jazz Yao-Tsung Wang, Software Engineering Manager @ Innova
                  Solutions
                </span>
              </q>
            </div>
          </div>
        </div>
      </div>
      <DownArrow color="tomato" />
    </section>
  )
}
