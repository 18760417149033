import React, { useState, useEffect, useRef } from "react"
import ImageModal from "./components/ImageModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDesktop } from "@fortawesome/free-solid-svg-icons"

let ModalContext = React.createContext(null)
const { Provider } = ModalContext

let ModalProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState(false)
  const modalRef = useRef()
  const handleShowModal = (show, content, refProp) => {
    setShowModal(show)
    if (content) {
      refProp.current.scrollIntoView()
      refProp.current.classList.add("show")
      document.body.classList.add("noScrolling")
      setModalContent(content)
    } else {
      setModalContent(null)
    }
  }
  const handleOutsideProjectImgClick = e => {
    if (modalRef.current.contains(e.target)) {
      return
    }
    if (document.body.classList.contains("noScrolling")) {
      handleShowModal(false)
      document.body.classList.remove("noScrolling")
    }
  }
  const handleKeydown = e => {
    const code = e.keyCode ? e.keyCode : e.which
    if (code === 27 && document.body.classList.contains("noScrolling")) {
      handleShowModal(false)
      document.body.classList.remove("noScrolling")
    }
  }

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideProjectImgClick)
    document.body.addEventListener("keydown", handleKeydown)
    return function () {
      document.body.removeEventListener("click", handleOutsideProjectImgClick)
      document.body.removeEventListener("keydown", handleKeydown)
    }
  }, [])

  return (
    <Provider
      value={{
        showModal,
        handleShowModal,
        modalContent,
        FontAwesomeIcon,
        faDesktop,
      }}
    >
      <ImageModal refProp={modalRef} />
      {children}
    </Provider>
  )
}

export { ModalProvider, ModalContext }
